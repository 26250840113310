import { useEffect, useState, useContext } from 'react';

import './Header.scss';
import BackImage from '../../assets/BackImage.jpg';
import { HeaderContext } from '../../App';
import { LoadSpinner } from '../LoadSpinner/LoadSpinner';

export const Header = () => {
  const [backLoaded, setBackLoaded] = useState(false);
  const { setHeaderLoaded } = useContext(HeaderContext);
  useEffect(() => {
    const backImg = new Image();
    backImg.src = BackImage;
    backImg.onload = () => {
      setBackLoaded(true);
      setHeaderLoaded(true);
    };
  });

  return (
    <div className="Header">
      {backLoaded && (
        <div className="Header__wrap">
          {/* <div className="Header__Nav__Wrap"></div> */}
          <img className="Header__BackImage" src={BackImage} alt="backimage" />
          <div className="Header__BlurRect"></div>
          <div className="Header__Text Header__Text__role">
            FE Engineer / Product Designer
          </div>
          <div className="Header__Text Header__Text__name">
            <span className="TextAccent">Kaito</span> Yamada
          </div>
        </div>
      )}
      {!backLoaded && <LoadSpinner />}
    </div>
  );
};
