import React, { useState } from 'react';

import './Projects.scss';
import { SectionTitle } from '../../App';
import LazyShow from '../../Components/LazyShow';

import Youbase from '../../assets/projects/youbase.jpg';
import YoubaseRank from '../../assets/projects/youbaseRank.png';
import YoubaseFlow from '../../assets/projects/youbaseflow.png';
import YoubaseCard from '../../assets/projects/youbasecard.png';
import YoubaseModal from '../../assets/projects/youbasemodal.jpg';
import WindBox from '../../assets/projects/flute.jpg';
import XFlute from '../../assets/projects/xflute.jpg';
import XFluteSax from '../../assets/projects/x-flute-sax.png';
import XFluteSoft from '../../assets/projects/x-flute-soft.jpg';
import Chienking from '../../assets/projects/chienking.jpg';
import Kamatree from '../../assets/projects/kamatree.jpg';
import SponavRank from '../../assets/projects/spnav.png';
import SponavPlayer from '../../assets/projects/spplayer.png';
// import Masalife from '../../assets/projects/masalife.jpg';
// import RecipeGif from '../../assets/projects/recipe.gif';
// import RecipeAll from '../../assets/projects/recipe-all.jpg';
import Phototropism from '../../assets/projects/phototropism.jpg';
import Tree360 from '../../assets/projects/kama360.png';
import Semi from '../../assets/projects/semi.jpg';
import Fitts from '../../assets/projects/fitts.jpg';
import FittsIntro1 from '../../assets/projects/fitts/intro1.jpeg';
import FittsIntro2 from '../../assets/projects/fitts/intro2.jpeg';
import FittsIntro3 from '../../assets/projects/fitts/intro3.jpeg';
import FittsIntro4 from '../../assets/projects/fitts/intro4.jpeg';
import FittsIntro5 from '../../assets/projects/fitts/intro5.jpeg';
import FittsEx1 from '../../assets/projects/fitts/ex1.jpeg';
import FittsEx2 from '../../assets/projects/fitts/ex2.jpeg';
import FittsEx3 from '../../assets/projects/fitts/ex3.jpeg';
import FittsRes1 from '../../assets/projects/fitts/res1.jpeg';
import FittsRes2 from '../../assets/projects/fitts/res2.jpeg';
import OdekakeMemo from '../../assets/projects/odekake.jpg';

import MarkerDrone from '../../assets/projects/marker-drone.jpg';
import WebDrone from '../../assets/projects/web-drone.jpg';

type ProjectItem = {
  odekake: boolean;
  youbase: boolean;
  kamatree: boolean;
  fitts: boolean;
  windbox: boolean;
  xflute: boolean;
  chienking: boolean;
  recipe: boolean;
  semi: boolean;
  webdrone: boolean;
  markerdrone: boolean;
};
const initObj = {
  odekake: false,
  youbase: false,
  kamatree: false,
  fitts: false,
  windbox: false,
  xflute: false,
  chienking: false,
  recipe: false,
  semi: false,
  webdrone: false,
  markerdrone: false,
};

export const Projects = () => {
  const Chips = (props: { item: string[] }) => {
    return (
      <div className="Modal__Chips">
        {props.item.map((chip) => {
          return (
            <div className="Modal__Chips__Chip">
              <div className="Modal__Chips__Chip__Text">{chip}</div>
            </div>
          );
        })}
      </div>
    );
  };

  const Project = (props: {
    keyName:
      | 'odekake'
      | 'youbase'
      | 'kamatree'
      | 'fitts'
      | 'windbox'
      | 'xflute'
      | 'chienking'
      | 'recipe'
      | 'semi'
      | 'webdrone'
      | 'markerdrone';
    mainText: string;
    mainImg: string;
    description?: {
      title?: string;
      beforeImageText?: string;
      afterImageText?: string;
      image?: string[];
      minImage?: string;
      subTitle?: boolean;
      youtube?: string;
      url?: string;
    }[];

    subText?: string;
    subImg?: string[];
    chipItem?: string[];
    right?: boolean;
  }) => {
    const [modalState, setModalState] = useState<ProjectItem>(initObj);
    const [blurState, setBlurState] = useState(false);
    return (
      <React.Fragment>
        {blurState && (
          <div
            className="Projects__Blur"
            onClick={() => {
              setBlurState(false);
              setModalState(initObj);
            }}
          ></div>
        )}

        <button
          className="Projects__Wrap"
          onClick={() => {
            if (!modalState[props.keyName]) {
              setModalState({ ...modalState, [props.keyName]: true });
            }
            if (!blurState) setBlurState(true);
          }}
        >
          <img
            className="Projects__Image"
            src={props.mainImg}
            alt={props.keyName}
          ></img>
        </button>
        {modalState[props.keyName] && (
          <div className="Modal">
            <div className="Modal__Top">
              <img
                className="Modal__Image"
                src={props.mainImg}
                alt={props.keyName}
                onClick={() => {
                  setBlurState(false);
                  setModalState(initObj);
                }}
              ></img>
              <div className="Modal__Title">
                <div className="Modal__Title__SubText">{props.subText}</div>
                <div className="Modal__Title__MainText">{props.mainText}</div>
                {props.chipItem && <Chips item={props.chipItem} />}
              </div>
            </div>
            <div className="Modal__Bottom">
              {props.description &&
                props.description.map((item) => {
                  return (
                    <div className="Modal__Desctiption__Wrap">
                      {item.title && (
                        <div
                          className={
                            !item.subTitle
                              ? 'Modal__Desctiption__Title'
                              : 'Modal__Desctiption__Title Modal__Desctiption__SubTitle'
                          }
                        >
                          {item.title}
                        </div>
                      )}
                      {item.beforeImageText && (
                        <div className="Modal__Desctiption__Content">
                          {item.beforeImageText
                            .split('<br/>')
                            .map((str, index) => (
                              <React.Fragment key={index}>
                                {str}
                                <br />
                              </React.Fragment>
                            ))}
                        </div>
                      )}
                      {item.url && (
                        <div className="Modal__Desctiption__Content">
                          <a style={{ color: 'white' }} href={item.url}>
                            {item.url}
                          </a>
                        </div>
                      )}
                      {item.image && (
                        <div
                          className="Modal__Desctiption__ImageWrap"
                          style={
                            item.minImage
                              ? { width: item.minImage, height: '100%' }
                              : undefined
                          }
                        >
                          {item.image?.map((item) => {
                            return (
                              <img
                                className="Modal__Desctiption__Image"
                                src={item}
                                alt="subImage"
                              ></img>
                            );
                          })}
                        </div>
                      )}
                      {item.youtube && (
                        <div className="Modal__Desctiption__Youtube">
                          <iframe
                            width="40%"
                            height="250"
                            src={`${item.youtube}?controls=0`}
                            title="YouTube video player"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                          ></iframe>
                        </div>
                      )}
                      {item.afterImageText && (
                        <div className="Modal__Desctiption__Content">
                          {item.afterImageText
                            .split('<br/>')
                            .map((str, index) => (
                              <React.Fragment key={index}>
                                {str}
                                <br />
                              </React.Fragment>
                            ))}
                        </div>
                      )}
                    </div>
                  );
                })}
              {props.subImg && (
                <div className="Modal__Desctiption__ImageWrap">
                  {props.subImg?.map((item) => {
                    return (
                      <img
                        className="Modal__Desctiption__Image"
                        src={item}
                        alt="subImage"
                      ></img>
                    );
                  })}
                </div>
              )}
            </div>
          </div>
        )}
      </React.Fragment>
    );
  };

  return (
    <div className="Projects">
      <SectionTitle beforeAccent="pr" accent="o" afterAccent="jects" />

      <div>
        <Project
          keyName="odekake"
          mainText="odekake memo"
          subText="おでかけプラン作成アプリ"
          description={[
            {
              beforeImageText:
                'おでかけプランを既存のプランからでも新規でも作成できるiOSアプリ',
              url: 'https://apps.apple.com/jp/app/odekake-memo/id1604115709',
              youtube: 'https://www.youtube.com/embed/iSm9nf87BMg',
            },
          ]}
          chipItem={['react native', 'typescript', 'figma']}
          mainImg={OdekakeMemo}
        />
        <Project
          keyName="youbase"
          mainText="youbase"
          subText="プロ野球選手名鑑アプリ"
          description={[
            {
              title: 'コンセプト',
              beforeImageText:
                'youbaseでは、以下のようなアプリを目指した。<br/>・データを深掘りできるような設計<br/>・ライトなファンでも使いたいと思えるUI/UX設計<br/>・選手をもっと知り、好きになれるような設計',
              youtube: 'https://www.youtube.com/embed/AeWprl5fmsU',
            },
            {
              title: 'ランキング画面',
              beforeImageText:
                '既存サービスのランキング画面では、重要な3指標におけるトップ3までの選手が掲出されている。この3指標の1位に関心をもつファンは多く、その点では良い表示である。一方で、「4位以降の選手を閲覧したい場合」や「他の指標のランキングを見たい場合」はメニューから目的の指標を選択する必要がある。',
              image: [SponavRank],
              afterImageText:
                'このような設計では、2タップが必要な上、多数な選択肢から目的の文字を見つける必要がありユーザの負荷は大きい。また、関心の低い指標の良い成績の選手について知る機会を損失している。',
            },
            {
              beforeImageText:
                'youbaseではユーザ負荷の低い操作で、多くの選手を知ることができる設計を心がけた。',
              image: [YoubaseRank],
              afterImageText:
                '関心の高い3指標のトップ3は初期表示で閲覧できるようにし、縦スクロールで全ての指標のトップ選手を、横スクロールでランキングを深掘りできるような設計にした。また、アイコンを利用することで、興味のあるチームの選手を見つけやすいようにした。',
            },
            {
              title: '選手個人画面',
              beforeImageText:
                '既存サービスでは、個人の成績や情報を詳しく見たい場合、選手を選択して成績一覧を表示する。',
              image: [SponavPlayer],
              afterImageText:
                'このとき、画面遷移は一方通行で、ある選手の成績一覧を表示したところで行き止まりである。他の指標や他の選手について気になった場合も最初まで戻るしかなく、データの深掘りができない。',
            },
            {
              beforeImageText:
                'youbaseでは画面遷移についても見直し、気になった情報を深掘りできる設計にした。',
              image: [YoubaseFlow],
            },
            {
              beforeImageText:
                'また、個人画面の各指標のデータ表示画面では、前後の成績の選手情報や、全体での位置などもわかるようなデザインにした。これにより画面遷移せずともより多くの情報や選手について知る機会が増えるだろう。',
              image: [YoubaseCard],
              minImage: '40%',
            },
            {
              beforeImageText:
                'コンセプトにもあるように、youbaseでは選手のことをもっと知りもっと好きになってほしいと考えているため、選手のSNSやグッズページを表示できるようにした。',
              image: [YoubaseModal],
            },
          ]}
          chipItem={['react native', 'typescript', 'figma']}
          mainImg={Youbase}
        />
      </div>
      <LazyShow>
        <div>
          <Project
            keyName="fitts"
            mainText="drone-fitts"
            subText="ドローン操作のモデル化"
            description={[
              {
                title: '概要',
                beforeImageText:
                  'GUIでの操作のモデル化を基に、ドローン操作のモデル化を行なった修士研究。ドローン操作のモデル化により操作時間予測やインタフェース評価が可能になり、ドローン操縦のUI向上に貢献した。',
              },
              {
                title: '背景',
                beforeImageText:
                  'HCI分野において、操作のモデル化は重要なテーマである',
                image: [FittsIntro1],
              },
              {
                beforeImageText:
                  'GUIでは、各操作のモデル化が行われており、より良いUI/UXについて予測や分析することができる',
                image: [FittsIntro2],
              },
              {
                beforeImageText:
                  'そして、より良いUIについての評価や議論がモデル化により可能になっている',
                image: [FittsIntro3],
              },
              {
                beforeImageText:
                  '近年、ドローンの普及は凄まじく、様々な操縦UIが提案されているが、モデル化がされていないため、評価や予測などができない',
                image: [FittsIntro4],
              },
              {
                beforeImageText:
                  'そこで、GUIでモデル化されている操作と同じ操作のモデル化をドローンでも目指した',
                image: [FittsIntro5],
              },
              {
                title: '実験',
                beforeImageText:
                  '実際に各操作の実験を行い、計測、観察を行なった',
                image: [FittsEx1, FittsEx2, FittsEx3],
                minImage: '32.8%',
              },
              {
                beforeImageText: '実験結果を基に操作のモデル化を行なった',
                image: [FittsRes2],
              },
              {
                beforeImageText:
                  'また、実験参加者の操縦について考察分析などを行なった',
                image: [FittsRes1],
              },
              {
                beforeImageText:
                  'これらの研究は、CHI2019でのポスター発表、HCII2019での登壇発表など2回の国際発表とHCI研究会にて3回の国内発表を行なった。HCI研究会では「HCI研究会学生奨励賞」を受賞した。',
                youtube: 'https://www.youtube.com/embed/TyjpMxyI9q8',
              },
            ]}
            chipItem={['Fitts’Law', 'HCI']}
            mainImg={Fitts}
          />
          <Project
            keyName="kamatree"
            mainText="kamatree"
            subText="均等に光があたる植木鉢"
            mainImg={Kamatree}
            description={[
              {
                beforeImageText:
                  '植物は光の方向に成長する「光屈性」というものがあり、窓際の植物はアンバランスに成長してしまうことがある。',
                image: [Phototropism],
                minImage: '40%',
              },

              {
                beforeImageText:
                  'kamatreeは均等に光が当たり光屈性を防ぐ植木鉢を目指した。四隅の光センサにより光が当たる量を計算し、均等に光が当たるように回転する植木鉢である。<br/><br/>以下のことに注意し、デザインおよびモデリングを行なった。<br/>・配線が収まる範囲でコンパクトな立方体デザイン<br/>・安定感があり目立たず開閉できる上下の構造<br/>・光センサが稼働する範囲で、目立たないような配置',
                image: [Tree360, Kamatree],
                minImage: '40%',
                afterImageText:
                  'kamatreeは、グッドデザイン賞に応募し最終選考まで通過した。',
              },
            ]}
            chipItem={['3dprinter', 'fusion360', 'arduino']}
          />
          {/* <Project
            keyName="recipe"
            mainText="recipe site"
            subText="友人のレシピサイト"
            description={[
              {
                title: '',
                beforeImageText:
                  '友人の料理レシピサイトのデザインを作成。友人の意見をもとに、「最新の料理レシピ」「人気の料理レシピ」を閲覧できるホーム画面、食材やジャンルで絞り込みできる検索画面、食材やレシピが閲覧できるレシピ画面を設計した',
                image: [RecipeAll],
                // minImage: '80%',
              },
              {
                title: '',
                beforeImageText:
                  'レシピ詳細画面では、買い物時の買い忘れやどこまで進めたかがわかるようにチェックできるデザインにした',
                image: [RecipeGif],
                minImage: '60%',
              },
            ]}
            chipItem={['figma']}
            mainImg={Masalife}
          /> */}
        </div>
      </LazyShow>
      <LazyShow>
        <div>
          <Project
            keyName="windbox"
            mainText="unit-wind-box"
            subText="発音技術の継承"
            description={[
              {
                title: '課題',
                beforeImageText:
                  '管楽器は鍵盤楽器などに比べ、音を出す難易度が高く、多くの練習時間を要する。一方で、管楽器は音色が基本的には1種類なので、異なる音色で表現したい場合は別の楽器を新たに習得する必要がある。そのとき「また1から練習するのが大変」「これまで練習してきたものが無駄になってしまう」といった気持ちが生まれてしまう人もいるだろう。',
              },
              {
                title: 'コンセプト',
                beforeImageText:
                  'そこで、これまで培った管楽器の発音技術を活かしたまま、別の音色を出せるような楽器を作れないかと考えた。フルートをプロトタイプモデルとし、発音技術を継承できるunit-wind-boxを作成した。',
                youtube: 'https://www.youtube.com/embed/TDK37JBhGh8',
                afterImageText:
                  '管楽器からなってしまう音を抑える機構にし、繊細な呼吸コントロールを電子音楽の表現に利用できるような楽器を目指した。',
                minImage: '40%',
              },
            ]}
            chipItem={['演奏表現', 'arduino', 'max / msp']}
            mainImg={WindBox}
          />
          <Project
            keyName="xflute"
            mainText="ex-flute"
            subText="運指技術の継承"
            description={[
              {
                title: '課題',
                beforeImageText:
                  '楽器の出す音を決める「運指」を習得するのには時間がかかる。特に管楽器は物理的に音程が変わるように穴を塞ぐため、不規則な運指であり習得にも時間がかかる。管楽器は、楽器ごとに運指が違うため、鍵盤楽器や弦楽器と異なり他の楽器への応用が効かない。',
              },
              {
                title: 'コンセプト',
                beforeImageText:
                  'そこで、管楽器でも習得した運指を活かして新しい音色で表現ができるような楽器を目指した。フルートの運指をプロトタイプモデルとし、サックスやトランペットのような音色を出せるような試みを行なった',
                image: [XFluteSax],
                afterImageText:
                  'サックスの発音機構を取り付けフルートの運指のままサックスの音色で表現できるようにした',
              },
              {
                title: '制約',
                beforeImageText:
                  '当然発音機構をただつけただけでは、物理的な音程の制約があり、音程が安定しない音域が存在する。そこで、実際の周波数を測り、出したい音域を指定することで、最適な接続機構を出力するソフトウェアを作成した。',
                image: [XFluteSoft],
                afterImageText:
                  'これにより制約付きだが、習得した運指を活かして新たな音色表現を可能にする楽器となった。',
              },
            ]}
            chipItem={['演奏表現', 'max / msp', '3dprinter', 'fusion360']}
            mainImg={XFlute}
          />
        </div>
      </LazyShow>

      <LazyShow>
        <div>
          <Project
            keyName="webdrone"
            mainText="web-drone"
            subText="ブラウザで複数人でドローン操作"
            description={[
              {
                title: 'コンセプト',
                beforeImageText:
                  '商業用ドローンは一般的にアプリをインストールし操作し、1人で操作するのが当たり前であった。それに対し、多くの人が一緒にドローンで遊んだり、ドローンでパフォーマンスできるようなものを目指した。',
                youtube: 'https://www.youtube.com/embed/TX4YoDC39sQ',
                afterImageText:
                  'ブラウザで複数人でドローン操作が可能になり、これまでになかった遊び方や気軽にドローンができるようになった。',
              },
              {
                title: '発展',
                beforeImageText:
                  'その後発展させ、ドローンパフォーマンスに特化したソフトウェアを作成した。',
                youtube: 'https://www.youtube.com/embed/4tydPfyzQ0w',
                afterImageText:
                  'Billboard Japan主催のLIVE MUSIC HACKASONGにて審査員賞を受賞した。',
              },
            ]}
            chipItem={['ドローン', 'パフォーマンス', 'JavaScript']}
            mainImg={WebDrone}
          />
          <Project
            keyName="markerdrone"
            mainText="marker-drone"
            subText="マーカーでドローンを操縦"
            description={[
              {
                title: 'コンセプト',
                beforeImageText:
                  'ドローンの操作は、アプリをダウンロードし、そのアプリのコントローラUIを習得する必要があり、人によっては障壁が高いと感じる場合がある。そこで、マーカーで操縦できるようなドローンの開発を行なった。',
                youtube: 'https://www.youtube.com/embed/MOVsp8EbezY',
                afterImageText:
                  'これにより、子供でも気軽にドローンで遊ぶことができるようになった。',
              },
            ]}
            chipItem={['ドローン', 'Fusion360', '3dprinter', 'JavaScript']}
            mainImg={MarkerDrone}
          />
        </div>
      </LazyShow>
      <LazyShow>
        <div>
          <Project
            keyName="semi"
            mainText="semi-heart"
            subText="「生」をテーマにしたメディアアート"
            description={[
              {
                title: '',
                beforeImageText:
                  '「生」をテーマにメディアアートを作成する機会があり、作成した作品。',
                youtube: 'https://www.youtube.com/embed/Hr1HHTTbyNo',
                afterImageText:
                  'セミの抜け殻は、生まれる過程で必要になったものであり、抜け殻自体をみたときに「生きているもの」という感覚も「死んているもの」という感覚もなかった。そんな抜け殻に、スピーカーとライトで不規則な音、振動、光を加え、生命感を少しでも感じられるように試みた。',
              },
            ]}
            chipItem={['3dprinter', 'Fusion360', 'arduino']}
            mainImg={Semi}
          />
          <Project
            keyName="chienking"
            mainText="Chienking!"
            subText="長時間の遅延証明書の獲得を目指すiOSゲーム"
            description={[
              {
                // title: '概要',
                beforeImageText:
                  '大学受験期に大雪で「76時間の遅延証明書」を受け取った自身の経験をもとに作成したiOSゲーム。より長時間の遅延証明書を獲得するために、適切なタイミングで電車にアイテムをぶつけて電車を遅延させるゲーム。遅延時間によってアイテムや電車の種類も変化。遅延させた電車はコレクションできる。',
                // image: [Chienking],
                afterImageText:
                  'iOSアプリとしてリリースした。Swift以降に伴い開発停止。',
              },
            ]}
            chipItem={['objective-c', 'iOS']}
            mainImg={Chienking}
          />
        </div>
      </LazyShow>
    </div>
  );
};
