import React, { useState } from 'react';
import './AboutMe.scss';
import { aboutmeData } from '../../assets/aboutme';
import { SectionTitle } from '../../App';
import { ChevronRight, ChevronDown, ExternalLink } from 'react-feather';

export type AboutContent = {
  title: string;
  year: {
    beforeYear: number;
    afterYear?: number;
    accent?: number;
  };
  description: string;
  accent?: boolean;
  lastPart?: boolean;
  chips?: string[];
  url?: {
    title: string;
    src: string;
  };
};

export const AboutMe = () => {
  const Expand = (props: AboutContent) => {
    const [expandState, setExpandState] = useState(props.accent ? true : false);

    return (
      <div className="Expand">
        <div className="Expand__BarCircle__Wrap">
          <div
            className="Expand__BarCircle__Circle"
            style={props.accent ? { backgroundColor: '#0f6d82' } : {}}
          ></div>

          <div
            className={
              props.lastPart
                ? 'Expand__BarCircle__Bar Expand__BarCircle__Last'
                : 'Expand__BarCircle__Bar'
            }
          ></div>
        </div>
        <div className="Expand__Title">
          <div className="Expand__Year">
            {props.year.beforeYear} {props.year.afterYear ? '-' : ''}{' '}
            {props.year.afterYear}
            <span className="TextAccent">{props.year.accent}</span>
          </div>

          {!expandState && (
            <button
              className="Expand__Title__button"
              onClick={() => {
                setExpandState(true);
              }}
            >
              <ChevronRight
                className="Expand__Title__Icon"
                color="#4b4b4b"
                strokeWidth={3}
              />
            </button>
          )}
          {expandState && (
            <button
              className="Expand__Title__button"
              onClick={() => {
                setExpandState(false);
              }}
            >
              <ChevronDown
                className="Expand__Title__Icon"
                color="#4b4b4b"
                strokeWidth={3}
              />
            </button>
          )}

          <button
            className="Expand__Title__Text__button"
            onClick={() => {
              setExpandState(!expandState);
            }}
          >
            <div className="Expand__Title__Text">{props.title} </div>
          </button>
        </div>

        {expandState && (
          <React.Fragment>
            <div className="Expand__Description">
              {props.description.split('<br/>').map((str, index) => (
                <React.Fragment key={index}>
                  {str}
                  <br />
                </React.Fragment>
              ))}
              {props.url && (
                <div className="Expand__URL">
                  <a
                    href={props.url.src}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {props.url.title}
                  </a>
                  <ExternalLink className="Expand__URL__Icon" />
                </div>
              )}
              {props.chips && <Chips item={props.chips} />}
            </div>
          </React.Fragment>
        )}
      </div>
    );
  };

  const Title = (props: {
    accent: string;
    beforeAccent?: string;
    afterAccent?: string;
  }) => {
    return (
      <div className="AboutMe__Title">
        <div className="AboutMe__Title__Wrap">
          <div className="AboutMe__Title__Text">
            {props.beforeAccent}
            <span className="TextAccent">{props.accent}</span>
            {props.afterAccent}
          </div>
          <div className="AboutMe__Title__Bar"></div>
        </div>
      </div>
    );
  };

  const Chips = (props: { item: string[] }) => {
    return (
      <div className="Chips">
        {props.item.map((chip) => {
          return (
            <div className="Chips__Chip">
              <div className="Chips__Chip__Text">{chip}</div>
            </div>
          );
        })}
      </div>
    );
  };

  return (
    <div className="AboutMe">
      <SectionTitle accent="a" afterAccent="bout me" />
      {/* experience */}
      <div className="AboutMe__Wrap">
        <Title accent="e" afterAccent="xperience" />
        {aboutmeData.career.map((item, index) => {
          return (
            <Expand
              title={item.title}
              year={item.year}
              description={item.description}
              chips={item.chips}
              accent={item.accent}
              lastPart={item.lastPart}
              key={index}
              url={item.url}
            />
          );
        })}
      </div>

      {/* education */}
      <div className="AboutMe__Wrap">
        <Title beforeAccent="e" accent="d" afterAccent="ucation" />
        {aboutmeData.education.map((item, index) => {
          return (
            <Expand
              title={item.title}
              year={item.year}
              description={item.description}
              chips={item.chips}
              accent={item.accent}
              lastPart={item.lastPart}
              key={index}
              url={item.url}
            />
          );
        })}
      </div>

      {/* research */}
      <div className="AboutMe__Wrap">
        <Title beforeAccent="re" accent="s" afterAccent="earch" />
        {aboutmeData.research.map((item, index) => {
          return (
            <Expand
              title={item.title}
              year={item.year}
              description={item.description}
              chips={item.chips}
              accent={item.accent}
              lastPart={item.lastPart}
              key={index}
              url={item.url}
            />
          );
        })}
      </div>

      {/* award */}
      <div className="AboutMe__Wrap">
        <Title beforeAccent="awa" accent="r" afterAccent="d" />
        {aboutmeData.award.map((item, index) => {
          return (
            <Expand
              title={item.title}
              year={item.year}
              description={item.description}
              chips={item.chips}
              accent={item.accent}
              lastPart={item.lastPart}
              key={index}
              url={item.url}
            />
          );
        })}
      </div>
    </div>
  );
};
