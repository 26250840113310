import { AboutContent } from '../Components/AboutMe/AboutMe';

type AboutMe = {
  career: AboutContent[];
  education: AboutContent[];
  research: AboutContent[];
  award: AboutContent[];
};

export const aboutmeData: AboutMe = {
  career: [
    {
      title: 'ヤフー株式会社',
      year: { beforeYear: 2020, afterYear: 202, accent: 3 },
      description:
        '2020年新卒入社。検索結果画面に表示されるモジュールのFE開発を担当。コマース領域を中心に担当。UXエンジニアチームやUXリサーチチームにも所属し、ユーザー分析や課題整理、施策立案も実施。ユーザーインタビューやワークショップなども主催。ユーザ行動分析のためのログを付与する業務から分析する業務まで実施。',
      accent: true,
      chips: [
        'コマース',
        'FE',
        'UXR',
        'ユーザー分析',
        'ABテスト',
        '行動ログ',
        'スクラム',
        'React',
        'TypeScript',
        'Express',
        'SQL',
      ],
      url: {
        title: 'ヤフー株式会社（会社概要）',
        src: 'https://about.yahoo.co.jp/info/',
      },
    },
    {
      title: 'ブルーモ証券株式会社 副業',
      year: { beforeYear: 2022, afterYear: 2023 },
      description:
        '新規証券アプリ立ち上げに際し、アプリの要件定義やデザイン業務を副業として行う。',
      chips: ['要件定義', 'デザイン', 'Figma', 'FigJam'],
      url: {
        title: 'ブルーモ証券株式会社',
        src: 'https://bloomo.co.jp/',
      },
    },
    {
      title: 'インフラトップ株式会社 副業',
      year: { beforeYear: 2022 },
      description: 'UI/UXデザインのオンライン講座のメンターを副業として行う。',
      chips: ['UI', 'UX', 'デザイン'],
      url: {
        title: 'UIUXデザインコース',
        src: 'https://web-camp.io/courses/uiUxDesign/',
      },
    },
    {
      title: '株式会社ネクストベース 技術インターン',
      year: { beforeYear: 2018, afterYear: 2020 },
      description:
        '野球のデータ分析・コンサルティングを専門とする会社にて2年間フロントエンド開発とデータ分析のインターンとして業務。プロ野球選手が使用するデータ分析サービスやアマチュア野球向けの動画閲覧/スコア入力サービスなどを開発。プロ野球データ分析業務も実施。',
      chips: ['FE', 'デザイン', '分析', 'Vue.js', 'JavaScript', 'Python'],
      url: {
        title: 'ネクストベース株式会社（会社概要）',
        src: 'https://nextbase.co.jp/',
      },
    },

    {
      title: 'ヤマハ株式会社 技術インターン',
      year: { beforeYear: 2017 },
      description:
        '「マルチメディアパフォーマンス」をテーマとする技術インターンに参加。演奏に合わせて香りを出すデバイスとソフトウェアを開発。また業務委託で、ドローンパフォーマンスシステムの開発も行った。',
      chips: ['Max / Msp', 'Javascript', 'Express'],
      lastPart: true,
      url: {
        title: 'ヤマハ株式会社（会社概要）',
        src: 'https://www.yamaha.com/ja/about/',
      },
    },
  ],
  education: [
    {
      title: '明治大学大学院 先端数理科学研究科 先端メディアサイエンス専攻',
      year: { beforeYear: 2018, afterYear: 2020 },
      accent: true,
      description:
        'ドローンにおける操作のモデル化を研究し、理学修士号を取得。CHI2019でのポスター発表、HCII2019での登壇発表など2回の国際発表とHCI研究会にて3回の国内発表を行なった。HCI研究会では「HCI研究会学生奨励賞」を受賞した。コンピュータ操作のUIについても研究した。',
      chips: ['HCI', 'ドローン', 'UI', 'UX', 'CHI', 'Fitts’Law'],
      url: {
        title: '修士論文発表資料',
        src: 'https://drive.google.com/file/d/1g0vIonHWRx8YKYg0MSuVWP6fqDklbtja/view?usp=sharing',
      },
    },
    {
      title: '明治大学 総合数理学部 先端メディアサイエンス学科',
      year: { beforeYear: 2014, afterYear: 2018 },
      description:
        '演奏技術の継承や演奏表現の拡張について研究し、理学学士号を取得。別の楽器の発音機構を利用し異なる音色の演奏を可能にする研究や、アコースティック楽器の発音機構で電子音楽を演奏する楽器の開発などを行なった。その他にも機械学習を用いた楽器練習支援や筋電を用いて演奏表現の可能性を広げる研究などを行なった。',
      lastPart: true,
      chips: ['HCI', '演奏表現', '演奏支援'],
      url: {
        title: '卒業論文発表資料',
        src: 'https://drive.google.com/file/d/1XMbPhYYP5S1s3hYU3aJaxklUCW-tkOJI/view?usp=sharing',
      },
    },
  ],
  research: [
    {
      title: 'ドローン操縦におけるクロッシングの評価',
      year: { beforeYear: 2018, afterYear: 2020 },
      description:
        'ドローンの操縦手法や操縦UIを探索する上で必要となるモデルについて研究。ドアやトンネルなどの輪の通過の動きに焦点をあてた。モデル化と共に、ドローン操縦時のユーザのメンタル状態などを考察。HCII2019で国際登壇発表、HCI研究会で国内発表。「HCI研究会学生奨励賞」を受賞。',
      chips: ['HCI', 'ドローン', 'Fitts’Law', 'HCII2019', 'HCI181'],
      url: {
        title: 'Modeling Drone Crossing Movement with Fitts’ Law',
        src: 'https://research.miyashita.com/papers/I38',
      },
    },
    {
      title: 'ドローン操縦におけるポインティングの評価',
      year: { beforeYear: 2018, afterYear: 2020 },
      description:
        'ドローンの操縦手法や操縦UIを探索する上で必要となるモデルについて研究。着陸や停滞する動きに焦点をあてた。モデル化と共に、ドローン操縦時のユーザのメンタル状態などを考察。CHI2019で国際ポスター発表、HCI研究会で国内発表。',
      chips: ['HCI', 'ドローン', 'Fitts’Law', 'CHI2019', 'HCI179'],
      url: {
        title: 'Modeling Drone Pointing Movement with Fitts’ Law',
        src: 'https://research.miyashita.com/papers/I37',
      },
    },
    {
      title: 'Valve Icon: ポインティング高速化手法の提案',
      year: { beforeYear: 2019 },
      description:
        'コンピュータ操作において、ターゲットに一時的な壁を生成することで誤操作を減らす研究。従来のUIと比較し、効果的な条件について分析し、結果を基にアプリケーションを提案した。HCI研究会で国内発表。共著として参加した。',
      lastPart: true,
      chips: ['HCI', 'Fitts’Law', 'UI', 'CHI2019', 'HCI185'],
      url: {
        title:
          'Valve Icon: オーバーシュート後に生成される壁を用いたポインティング高速化手法の提案',
        src: 'https://research.miyashita.com/papers/D227',
      },
    },
  ],
  award: [
    {
      title: 'HCI研究会 学生奨励賞',
      year: { beforeYear: 2019 },
      description:
        ' 第181回HCI研究会にて「ドローン操縦におけるクロッシング評価」で登壇発表をし「HCI研究会 学生奨励賞」を受賞。',
      chips: ['HCI', 'ドローン', 'Fitts’Law', 'HCI181'],
      url: {
        title: '明治大学 先端数理科学研究科 受賞記事',
        src: 'https://www.meiji.ac.jp/ams/info/2019/6t5h7p000032mow0.html',
      },
    },
    {
      title: 'LIVE MUSIC HACK A SONG (Billboard Japan)審査員賞',
      year: { beforeYear: 2016 },
      description:
        '「ライブ体験の拡張」がテーマの「LIVE MUSIC HACK A SONG」に参加。「Dr.one -誰もがドローンパフォーマンスを行えるアプリケーション-」を開発し審査員賞を受賞した。',
      url: {
        title: '明治大学　総合数理学部 受賞記事',
        src: 'https://www.meiji.ac.jp/ims/news/2016/6t5h7p00000ms3zr.html',
      },
      chips: ['ドローン', 'パフォーマンス表現', 'JavaScript'],
    },
    {
      title: '第25回日本クラシック音楽コンクール フルートソロ全国4位',
      year: { beforeYear: 2015 },
      url: {
        src: 'https://www.kurakon.net/result_2015_6',
        title: '第25回日本クラシック音楽コンクール 大会結果',
      },
      description:
        '大学2年次に、第25回日本クラシック音楽コンクールに参加し、フルート部門 大学の部にて第4位を受賞した。特殊奏法が多く難易度の高い多久潤一朗作曲の「虹」を演奏した。',
      chips: ['フルート', '特殊奏法', '現代曲', '無伴奏'],
    },
    {
      title: '第21回日本クラシック音楽コンクール フルートソロ全国5位',
      year: { beforeYear: 2014 },
      description:
        '高校2年次に、第21回日本クラシック音楽コンクールに参加し、フルート部門 高校の部にて第5位を受賞した。Isang Yun作曲の「Etude No.5」を演奏した。入賞者による選抜演奏会にもオーケストラメンバーとして参加した。',
      chips: ['フルート', '特殊奏法', '現代曲', '無伴奏'],
      url: {
        src: 'https://www.kurakon.net/result_2011_6',
        title: '第21回日本クラシック音楽コンクール 大会結果',
      },

      lastPart: true,
    },
  ],
};
