import React, { useState, createContext } from 'react';
import './App.scss';

import {
  Figma,
  Max,
  ReactIcon,
  LightRoom,
  Keynote,
  VueIcon,
  Flute,
  Camera,
  Printer,
  Fusion,
} from './assets/SvgIcon';

import { Header } from './Components/Header/Header';
import { AboutMe } from './Components/AboutMe/AboutMe';
import { Projects } from './Components/Projects/Projects';
import LazyShow from './Components/LazyShow';

const BackGround = () => {
  return (
    <div className="BackGround">
      <div className="BackGround__wrap">
        <div className="BackGround__circle BackGround__circle__purple" />
        <div className="BackGround__circle BackGround__circle__green" />
        <div className="BackGround__blur" />
      </div>
    </div>
  );
};

export const SectionTitle = (props: {
  accent: string;
  beforeAccent?: string;
  afterAccent?: string;
}) => {
  return (
    <div className="SectionTitle">
      <div className="SectionTitle__Text">
        {props.beforeAccent}
        <span className="TextAccent">{props.accent}</span>
        {props.afterAccent}
      </div>
      <div className="SectionTitle__Bar"></div>
    </div>
  );
};

const Skills = () => {
  return (
    <div className="Skills">
      <SectionTitle beforeAccent="s" accent="k" afterAccent="ills" />

      <div className="Skills__Icons">
        <div className="Skills__Icon">
          <Figma />
          <div className="Skills__Icon__Text">figma</div>
        </div>
        <div className="Skills__Icon">
          <ReactIcon />
          <div className="Skills__Icon__Text">react</div>
        </div>

        <div className="Skills__Icon">
          <Max />
          <div className="Skills__Icon__Text">max</div>
        </div>
        <div className="Skills__Icon">
          <LightRoom />
          <div className="Skills__Icon__Text">lightroom</div>
        </div>

        <div className="Skills__Icon">
          <Fusion />
          <div className="Skills__Icon__Text">fusion</div>
        </div>
      </div>
      <LazyShow>
        <div className="Skills__Icons">
          <div className="Skills__Icon">
            <Keynote />
            <div className="Skills__Icon__Text">keynote</div>
          </div>

          <div className="Skills__Icon">
            <VueIcon />
            <div className="Skills__Icon__Text">vue</div>
          </div>

          <div className="Skills__Icon">
            <Flute />
            <div className="Skills__Icon__Text">flute</div>
          </div>
          <div className="Skills__Icon">
            <Camera />
            <div className="Skills__Icon__Text">camera</div>
          </div>
          <div className="Skills__Icon">
            <Printer />
            <div className="Skills__Icon__Text">3d printer</div>
          </div>
        </div>
      </LazyShow>
    </div>
  );
};

export const HeaderContext = createContext(
  {} as {
    headerLoaded: boolean;
    setHeaderLoaded: React.Dispatch<React.SetStateAction<boolean>>;
  }
);

function App() {
  const [headerLoaded, setHeaderLoaded] = useState(false);

  return (
    <div className="App">
      <meta name="viewport" content="width=1200" />
      <BackGround />

      <div className="App__Contents">
        <HeaderContext.Provider value={{ headerLoaded, setHeaderLoaded }}>
          <Header />
        </HeaderContext.Provider>
        {headerLoaded && (
          <React.Fragment>
            <LazyShow>
              <AboutMe />
            </LazyShow>

            <LazyShow>
              <Skills />
            </LazyShow>

            <LazyShow>
              <Projects />
            </LazyShow>
          </React.Fragment>
        )}
      </div>
    </div>
  );
}

export default App;
