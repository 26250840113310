import './LoadSpinner.scss';

export const LoadSpinner = () => {
  return (
    <div className="lds-facebook">
      <div></div>
      <div></div>
      <div></div>
    </div>
  );
};
